.projectCard,
.projectCard-long{
    height: 267px;
    width: 500px;
    border-radius: 20px ; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: linear-gradient(357.78deg, rgba(0, 0, 0, 0.92) 17.76%, rgba(0, 0, 0, 0) 48.07%);
    padding: 15px;
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    text-align: left;
    
}
.projectCard-long{
    width: 500px;
    height: 550px;
}
.projectCard img,
.projectCard-long img{
    width: 35px;
    height: 35px;
}
.projects-header{
    height: 100vh;
}
.projects-Heading-text{
    font-size: 120px;
    font-weight: 700;
    line-height: 146px;
    letter-spacing: 0.25em;
    /* color: #F8F8F869; */
    -webkit-text-stroke: #F8F8F869 3px;
    color: transparent;
    background:url(../images/back2.png);
    -webkit-background-clip: text;
    background-position: 0 0;
    animation: back 20s linear infinite;
}
@keyframes back{
    100%{
        background-position: 2000px 0;
    }
}

.projects-Heading-image img{
    height: 499px;
    width: 511px;
}

/* POPUP CARD */

.Project-about{
    width: 800px;
    height: 500px;
    background: #171B1E;
    border-radius: 10px;
    animation: slog 0.5s 1 ease;
    
}
@keyframes slog {
    0% {
        height: 0px;
        width: 0px;
    }

    100% {
        width: 550px;
        height: 550px;
    }
}
.TechStack-SubHeading{
    font-size: 50px;
    font-weight: 800;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: center;
    color:  #9A9A9A;
}

.bg-modal1,
.bg-modal2,
.bg-modal3,
.bg-modal4,
.bg-modal5,
.bg-modal6 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.747);
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 250;
}


@media screen and (max-width: 600px){
    .projectCard,
    .projectCard-long
    {
        height: 220px;
        width: 350px;
        
        font-size: 22px;
        line-height: 30px;
    }
    .projectCard img,
    .projectCard-long img{
        width: 20px;
        height: 20px;
    }

    .projects-header{
        height: 60vh;
    }
    .projects-Heading-text{
        font-size: 40px;
        line-height: 80px;
        letter-spacing: 0.25em;
    }

    /* POPUP CARD */

    .Project-about{
        width: 100vw;
        height: 600px;
        background: #171B1E;
        border-radius: 10px;
        animation: slog 0.5s 1 ease;
    }
    .TechStack-SubHeading{
        font-size: 30px;
        line-height: 45px;
    }
    
}